<template>
	<!-- 팝업 -->
	<div class="layerPop alert on" v-if="dialog">
		<div class="inner" style="width:320px">
			<strong class="tit">오브제핀 실이동 안내</strong>
			<div class="cont">
				<div class="inputTable">
					<table>
						<colgroup>
							<col style="width:54px" />
							<col style="width:auto" />
						</colgroup>
						<tbody>
							<tr>
								<th scope="row">실</th>
								<td>
									<select title="실 선택" v-model="data">
										<option v-for="(room, index) in rooms" :key="index" :value="room.id">
											{{ room.name }}
										</option>
									</select>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!-- btnArea -->
			<div class="btnArea">
				<button type="button" class="btn btnGray" @click="onClose(undefined)">
					취소
				</button>
				<button type="button" class="btn" @click="onSave">선택</button>
			</div>
			<!--// btnArea -->
		</div>
	</div>
	<!-- 팝업 -->
</template>
<script>
import room from '@/api/room';
export default {
	props: {
		value: {},
		floorPlanId: {
			type: Number,
		},
	},
	data() {
		return {
			dialog: false,
			popupResolve: null,
			data: null,
			rooms: null,
			tenantList: [],
		};
	},
	methods: {
		async onSave() {
			this.onClose(this.data);
		},
		onClose(value) {
			this.dialog = false;
			this.data = null;

			if (this.popupResolve) {
				this.popupResolve(value);
				this.popupResolve = null;
			}
		},
		select(value) {
			if (this.popupResolve) {
				return null;
			}

			if (value) {
				this.data = value.roomId;
			}

			let self = this;
			self.rooms = null;
			room
				.get({ floorPlanId: value.floorPlanId })
				.then(function(res) {
					self.rooms = res.data;
				})
				.catch(function(ex) {
					console.error(ex);
					this.$alert(`실 목록 조회 실패했습니다.\r\n${ex.message}`);
					return Promise.reject();
				});

			this.dialog = true;

			return new Promise(resolve => (this.popupResolve = resolve));
		},
	},
};
</script>

<style scoped></style>
