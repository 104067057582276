<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea v3">
			<div class="inner">
				<div class="location">
					<span @click="onBuilding(buildingId)" style="cursor: pointer">{{ buildingName }}</span>
					<span @click="onFloor(floorId)" style="cursor: pointer">{{ floorName }}</span>
					<span>{{ roomName }}</span>
				</div>
				<a href="javascript:void(0)" class="btnAddObject" @click="onEdit" v-if="auth == true">오브제추가</a>
			</div>
		</div>
		<!--// titArea -->
		<!-- boxWhite -->
		<div class="boxWhite">
			<div class="inner">
				<!-- boxLine -->
				<div class="boxLine">
					<!-- floorPlanArea -->
					<div ref="floorPlanArea" class="floorPlanArea">
						<img
							v-if="imageUrl"
							:style="{ position: 'absolute', top: `${roomData.offsetY}px`, left: `${roomData.offsetX}px` }"
							:src="imageUrl"
							alt=""
							:width="roomData.imageWidth * roomData.scale"
							:height="roomData.imageHeight * roomData.scale"
						/>
						<svg
							v-if="imageUrl"
							xmlns="http://www.w3.org/2000/svg"
							version="1.2"
							baseProfile="tiny"
							class="svg"
							ref="svgViewer"
							width="100%"
							height="100%"
							style="left: 0px; top: 0px"
							@mousedown="onMousedown"
							@touchstart="onTouchstart"
						>
							<g>
								<rect
									v-if="'rect' === roomData.type"
									:x="roomData.x * roomData.scale + roomData.offsetX"
									:y="roomData.y * roomData.scale + roomData.offsetY"
									:width="roomData.width * roomData.scale"
									:height="roomData.height * roomData.scale"
									rx="8"
									:fill="roomFill"
									:stroke="roomStroke"
									stroke-width="0"
								/>
								<circle
									v-if="'circle' === roomData.type"
									:cx="roomData.cx * roomData.scale + roomData.offsetX"
									:cy="roomData.cy * roomData.scale + roomData.offsetY"
									:r="roomData.r * roomData.scale"
									:fill="roomFill"
									:stroke="roomStroke"
									stroke-width="0"
								/>
								<polygon
									v-if="'polygon' === roomData.type"
									:points="
										roomData.points
											.map((it, idx) =>
												idx % 2 ? it * roomData.scale + roomData.offsetY : it * roomData.scale + roomData.offsetX,
											)
											.join(' ')
									"
									:fill="roomFill"
									:stroke="roomStroke"
									stroke-width="0"
								/>
							</g>
							<g
								v-for="(pin, idxPin) in objectPinsInUse"
								:key="`pin-${idxPin}`"
								style="cursor: pointer;"
								@click="onSelectPin(pin, $event)"
								@touchstart="onSelectPin(pin, $event)"
							>
								<rect
									v-show="selectedPin && selectedPin.id == pin.id"
									:x="pin.x * roomData.scale + roomData.offsetX"
									:y="pin.y * roomData.scale + roomData.offsetY"
									:width="pin.width * roomData.scale"
									:height="pin.height * roomData.scale"
									:fill="pinFill"
									:stroke="pinStroke"
									stroke-width="1"
								/>
								<rect
									width="18"
									height="26"
									fill="rgba(0,0,0,0)"
									:transform="
										`translate(${pin.x * roomData.scale +
											roomData.offsetX +
											(pin.width * roomData.scale) / 2 -
											5}, ${pin.y * roomData.scale + roomData.offsetY + (pin.height * roomData.scale) / 2 - 24})`
									"
								/>
								<path
									:transform="
										`translate(${pin.x * roomData.scale +
											roomData.offsetX +
											(pin.width * roomData.scale) / 2 -
											5}, ${pin.y * roomData.scale + roomData.offsetY + (pin.height * roomData.scale) / 2 - 24})`
									"
									d="M12.791,12.893,15.375,4.95a3.38,3.38,0,0,0,2.377-1.734L9.878,0a3.4,3.4,0,0,0,.469,2.925l-3.7,7.458A9.32,9.32,0,0,0,0,12.955L7.479,16.01,4.12,24.234l.778.318,3.359-8.224,7.479,3.054a9.32,9.32,0,0,0-2.945-6.489"
									:fill="roomStroke"
								/>
							</g>
						</svg>
					</div>
					<!--// floorPlanArea -->
					<a
						v-if="auth"
						href="javascript:void(0)"
						class="btnSet"
						style="position: absolute; z-index: 99"
						@click="onEdit"
					>
						설정
					</a>
				</div>
				<!--// boxLine -->
			</div>
		</div>
		<!--// boxWhite -->
		<!-- contents -->
		<div class="contents">
			<!-- tabWrap -->
			<div class="tabWrap">
				<ul>
					<li :class="curr[0]"><a @click="categoryTab(0)">글목록</a></li>
					<li :class="curr[1]"><a @click="categoryTab(1)">오브제</a></li>
				</ul>
				<div class="tabBox">
					<!-- 오브제 -->
					<div v-if="curr[1] == 'curr'">
						<!-- 2022-05-14 -->
						<div class="listTop v2">
							<div class="right">
								<select class="v2" title="구분" @change="reload()" v-model="objectCode">
									<option :value="null">전체</option>
									<option :value="menu.code" v-for="(menu, index) in menus" :key="index">{{ menu.name }}</option>
								</select>
							</div>
						</div>
						<!--// 2022-05-14 -->
						<div class="objectList02" v-if="objectPins">
							<ul>
								<li
									v-for="(objectPin, index) in objectPins"
									:key="index"
									:class="objectPin.isUse ? null : 'disabled'"
									:ref="`objectPin${objectPin.id}`"
									:tabindex="index"
									@focus="onFocusObjectPin(objectPin)"
									@blur="onBlurObjectPin(objectPin)"
								>
									<div class="imgBg">
										<img
											:src="objectPin.fileId ? `/file/${objectPin.fileId}` : '/resources/images/no-img02.svg'"
											alt=""
										/>
									</div>
									<div class="txtArea">
										<strong>{{ objectPin.objectName }}</strong>
										<a @click="viewMenu(index)" class="btnMenu" v-if="auth == true">메뉴</a>
										<div class="menu" :style="objectPin.style">
											<a @click="ablePin(objectPin, true)" v-if="!objectPin.isUse">활성화</a>
											<a @click="ablePin(objectPin, false)" v-else>비활성화</a>
											<a @click="moveObjectPin(objectPin)">실이동</a>
											<a @click="deleteObjectPin(objectPin)">삭제</a>
										</div>
										<p>
											<a
												@click="objectPin.isUse ? onMemoList(objectPin.roomId, objectPin.id) : null"
												class="btnMenoList"
												>{{ objectPin.memoCount }}</a
											>
											<a
												@click="objectPin.isUse ? onMemoRegist(objectPin.roomId, objectPin.id) : null"
												class="btnWeite"
												v-if="friendAuth == true"
												>글쓰기</a
											>
										</p>
									</div>
								</li>
							</ul>
						</div>
						<div v-else>
							<div class="memoList">
								<ul>
									<!-- noData -->
									<div class="noData">
										<p>등록된 오브제핀이 없습니다.</p>
									</div>
								</ul>
							</div>
						</div>
					</div>
					<!-- 오브제 -->

					<div v-else-if="curr[0] == 'curr'">
						<div class="memoList" v-if="memo.total > 0">
							<ul>
								<li v-for="(memo, index) in memoList" :key="index">
									<a @click="onMemo(memo.id)">
										<!-- location -->
										<div class="location">
											<span>{{ memo.buildingName }}</span>
											<span v-if="memo.floorId != null">{{ memo.floorName }}</span>
											<span v-if="memo.roomId != null">{{ memo.roomName }}</span>
											<span v-if="memo.objectPinId != null">{{ memo.objectName }}</span>
											<span v-if="memo.locations && memo.locations.length > 1" class="locationCount"
												>외 {{ memo.locations.length - 1 }}개</span
											>
										</div>
										<!--// location -->
										<!-- memo -->
										<div class="memo">
											<div class="user">
												<div class="imgArea">
													<p><img :src="`/file/${memo.creatorImageId}`" alt="" v-if="memo.creatorImageId" /></p>
												</div>
												<p>{{ memo.creatorName }}</p>
											</div>
											<div class="user" v-if="memo.projectName != null">
												<span> <strong>[프로젝트]</strong> </span>
												<span class="title">&nbsp;{{ memo.projectName }}</span>
												<span class="interval">
													&nbsp;({{ memo.projectBeginDate | dateFormatYear }}~{{
														memo.projectEndDate | dateFormatYear
													}})
												</span>
											</div>
											<div class="user" v-if="memo.schedule.name">
												<span>
													<strong>[스케줄]</strong>
												</span>
												<span class="title">&nbsp;{{ memo.schedule.name }}</span>
												<span class="interval"
													>&nbsp;({{ setIntervalStr(memo.schedule.intervalType) }}
													{{ setDateFormat(memo.schedule) }})</span
												>
											</div>
											<p class="date">
												<span>{{ memo.createDate | dateFormatYear }}</span>
												<span>{{ memo.createDate | dateFormatMonth }}</span>
											</p>
											<p>
												<span>
													<pre v-if="memo.content" style="font-family: 'Noto Sans KR', sans-serif">{{
														enterContent(memo.content).replace(/ /g, '&nbsp;')
													}}</pre>
												</span>
											</p>
										</div>
										<!--// memo -->
									</a>
									<!-- imgList -->
									<div class="devImgList">
										<div class="inBox">
											<p v-for="(memoFile, index) in memo.memoFiles" :key="index">
												<img
													:src="`/file/${memoFile.fileId}`"
													alt=""
													@click="onOpenImage(memoFile.fileId, memo.memoFiles)"
												/>
											</p>
										</div>
									</div>
									<!--// imgList -->
									<!-- viewInfo -->
									<div class="viewInfo">
										<a class="btnComment">{{ memo.commentCount }}</a>
										<a class="btnViews">{{ memo.views }}</a>
									</div>
									<!--// viewInfo -->
								</li>
							</ul>
							<!-- 글목록 -->
						</div>
						<div v-else>
							<div class="memoList">
								<ul>
									<!-- noData -->
									<div class="noData">
										<p>등록된 최신글이 없습니다.</p>
									</div>
								</ul>
							</div>
						</div>
					</div>
					<!-- 글목록 -->
				</div>
			</div>
			<!--// tabWrap -->
			<!-- btnFixed -->
			<div class="btnFriArea">
				<div class="inBox">
					<a @click="onMemoRegist(roomId)" class="btnEdit" v-if="friendAuth == true">메모하기</a>
				</div>
			</div>
			<!--// btnFixed -->
		</div>
		<!--// contents -->
		<PopUpImage ref="popUpImage"></PopUpImage>
		<PopupMoveRoom ref="popupMoveRoom"></PopupMoveRoom>
	</div>
	<!--// container -->
</template>
<script>
import { showSpinner, hideSpinner } from '@/utils/cmm';
import { dateFormatYear, dateFormatMonth, getAuth, sizeOfImage, setIntervalStr } from '@/utils/cmm';
import room from '@/api/room';
import memo from '@/api/memo';
import floorplan from '@/api/floorplan';
import floor from '@/api/floor';
import building from '@/api/building';
import friend from '@/api/friend';
import objectpin from '@/api/objectpin';
import PopUpImage from '../popup/popUpImage';
import PopupMoveRoom from './popupMoveRoom';
import code from '@/api/code';

const PADDING = 20;

export default {
	components: { PopUpImage, PopupMoveRoom },
	data() {
		return {
			roomId: Number(this.$route.params.id),
			// floorPlanId: Number(this.$route.params.fid),
			floorPlanId: null,
			floorId: '',
			buildingId: '',
			buildingName: '',
			floorName: '',
			roomName: '',
			imageUrl: null,
			roomData: {},
			// objectPins: [{ id: 1, objectId: 1, x: 150, y: 140, width: 50, height: 50 }],
			objectPins: null,
			curr: ['', ''],
			memoList: [],
			memo: {
				offset: 0,
				limit: 10,
				total: '',
			},
			selectedPin: null,
			thisImageId: null,
			auth: false,
			friendAuth: false,
			pinFill: '#EA5B5B33',
			pinStroke: '#EA5B5B',
			roomFill: '#0c234c33',
			roomStroke: '#0c234c',
			strokeWidth: 1,
			groupCode: 'OBCA',
			objectCode: null,
			menus: [],
		};
	},
	computed: {
		objectPinsInUse() {
			return this.objectPins && this.objectPins.filter(it => it.isUse);
		},
	},
	watch: {},
	filters: {
		dateFormatYear,
		dateFormatMonth,
	},
	created() {
		this.$nextTick(async () => {
			await this.reload();
			await this.categoryTab(0);
		});
	},
	mounted() {
		window.addEventListener('resize', this.updateFloorPlanArea);
	},
	beforeDestroy() {
		this.$EventBus.$off('paging');
		window.removeEventListener('resize', this.updateFloorPlanArea);
	},
	methods: {
		onEdit() {
			this.$router.push({
				name: 'roomEdit',
				params: { id: this.roomIds, floorPlanId: this.floorPlanId, imageId: this.thisImageId },
			});
		},
		async onSelectPin(pin) {
			await this.categoryTab(1);
			this.selectedPin = pin;
			this.focusPin();
		},
		focusPin() {
			if (!this.selectedPin) return;
			this.$nextTick(() => {
				const found = this.$refs['objectPin' + this.selectedPin.id];
				found && found[0] && found[0].focus();
			});
		},
		async reload() {
			try {
				showSpinner();
				const roomId = this.roomId;
				let resRoom = await room.getOne(roomId);
				this.roomName = resRoom.name;

				this.floorPlanId = resRoom.floorPlanId;
				// console.log('resRoom output');
				// console.log(resRoom);
				const vals = resRoom.shapeInfo.split(' ').map(it => Number(it));
				this.auth = getAuth().id === resRoom.creator ? true : false;
				let roomData = {
					id: resRoom.id,
					scale: 1,
					type: resRoom.shapeType,
					name: resRoom.name,
					ownership: resRoom.ownership,
					offsetX: 0,
					offsetY: 0,
					imageWidth: 1,
					imageHeight: 1,
				};
				switch (resRoom.shapeType) {
					case 'rect':
						Object.assign(roomData, {
							x: vals[0],
							y: vals[1],
							width: vals[2],
							height: vals[3],
							area: {
								x: vals[0],
								y: vals[1],
								width: vals[2],
								height: vals[3],
							},
						});
						break;
					case 'circle':
						Object.assign(roomData, {
							cx: vals[0],
							cy: vals[1],
							r: vals[2],
							area: {
								x: vals[0] - vals[2],
								y: vals[1] - vals[2],
								width: vals[2] * 2,
								height: vals[2] * 2,
							},
						});
						break;
					case 'polygon':
						{
							let result = [
								[vals[0], vals[0]],
								[vals[1], vals[1]],
							];
							for (let i = 2; i < vals.length; i += 2) {
								result[0][0] = Math.min(result[0][0], vals[i]);
								result[0][1] = Math.max(result[0][1], vals[i]);
								result[1][0] = Math.min(result[1][0], vals[i + 1]);
								result[1][1] = Math.max(result[1][1], vals[i + 1]);
							}
							Object.assign(roomData, {
								points: vals,
								area: {
									x: result[0][0],
									y: result[1][0],
									width: result[0][1] - result[0][0],
									height: result[1][1] - result[1][0],
								},
							});
						}
						break;
				}

				// console.log('resRoom resFloorplan');
				// console.log(resRoom);
				let resFloorplan = await floorplan.getOne(resRoom.floorPlanId);

				// console.log('result resFloorplan');
				// console.log(resFloorplan);
				let roomIds = {};
				let newRooms = await room.get({ floorPlanId: resRoom.floorPlanId });
				// console.log('newRooms');
				// console.log(newRooms);
				roomIds[resRoom.floorPlanId] = [];
				newRooms.data.map(room => {
					// console.log('roomid');
					// console.log(room.id);
					roomIds[resRoom.floorPlanId].push(room.id);
				});
				this.roomIds = roomIds;
				let resFloor = await floor.getOne(resFloorplan.floorId);
				this.buildingId = resFloor.buildingId;
				this.floorId = resFloor.id;
				this.floorName = resFloor.name;

				this.thisImageId = resFloorplan.imageId;
				if (resFloorplan.imageId) {
					const imageUrl = `/file/${resFloorplan.imageId}`;
					const { width, height } = await sizeOfImage(imageUrl);
					roomData.imageWidth = width;
					roomData.imageHeight = height;
					this.imageUrl = imageUrl;
				}

				let resBuilding = await building.getOne(resFloor.buildingId);
				this.buildingName = resBuilding.name;
				let allRooms = await room.get({ floorPlanId: resRoom.floorPlanId, shapeType: 'all' });
				let resObjectPin = await objectpin.get({ roomId, category: this.objectCode });

				const convObjectPin = function(it) {
					const vals = it.areaInfo.split(' ');
					return {
						id: it.id,
						objectId: it.objectId,
						floorPlanId: resRoom.floorPlanId,
						isUse: it.isUse,
						x: Number(vals[0]),
						y: Number(vals[1]),
						width: Number(vals[2]),
						height: Number(vals[3]),
						memoCount: it.memoCount,
						fileId: it.fileId,
						objectName: it.objectName,
						style: null,
					};
				};

				let objectPins = resObjectPin.data && resObjectPin.data.map(convObjectPin);
				objectPins && objectPins.forEach(it => (it.roomId = roomId));

				if (allRooms.data && 0 < allRooms.data.length) {
					resObjectPin = await objectpin.get({ roomId: allRooms.data[0].id, category: this.objectCode });
					const allObjectPins = resObjectPin.data && resObjectPin.data.map(convObjectPin);
					if (allObjectPins) {
						allObjectPins.forEach(it => (it.roomId = allRooms.data[0].id));
						objectPins = objectPins ? objectPins.concat(allObjectPins) : allObjectPins;
					}
				}

				objectPins &&
					objectPins.forEach(it => {
						if (!it.isUse) return;

						const area = roomData.area;
						if (area.x > it.x) {
							area.width += area.x - it.x;
							area.x = it.x;
						}
						if (area.y > it.y) {
							area.height += area.y - it.y;
							area.y = it.y;
						}

						if (area.x + area.width < it.x + it.width) {
							area.width += it.x + it.width - (area.x + area.width);
						}
						if (area.y + area.height < it.y + it.height) {
							area.height += it.y + it.height - (area.y + area.height);
						}
					});

				this.roomData = roomData;
				this.objectPins = objectPins;
				await this.friendInfo();
				await this.onGetObjectCode();
				this.updateFloorPlanArea();
				this.focusPin();
			} catch (ex) {
				console.error(ex);
				await this.$alert(`실 조회 실패했습니다.\r\n${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		updateFloorPlanArea() {
			const roomData = this.roomData;
			const clientWidth = this.$refs.floorPlanArea.clientWidth;
			const clientHeight = this.$refs.floorPlanArea.clientHeight;

			const scaleX = (clientWidth - PADDING * 2) / roomData.area.width;
			const scaleY = (clientHeight - PADDING * 2) / roomData.area.height;
			if (scaleX < scaleY) {
				const offset = (roomData.area.y + roomData.area.height * 0.5) * scaleX - clientHeight * 0.5;
				roomData.offsetX = -roomData.area.x * scaleX + PADDING;
				roomData.offsetY = -offset;
				roomData.scale = scaleX;
			} else {
				const offset = (roomData.area.x + roomData.area.width * 0.5) * scaleY - clientWidth * 0.5;
				roomData.offsetX = -offset;
				roomData.offsetY = -roomData.area.y * scaleY + PADDING;
				roomData.scale = scaleY;
			}
		},
		async categoryTab(index) {
			if ('curr' == this.curr[index]) return;

			this.curr = [];
			this.curr[index] = 'curr';
			if (index === 0) {
				this.memoList = [];
				this.memo.offset = 0;
				this.$EventBus.$on('paging', () => {
					if (this.memo.total <= this.memo.offset) return;
					this.onGetMemo();
				});
				await this.onGetMemo();
			} else if (index === 1) {
				this.$EventBus.$off('paging');
				this.reload();
			}
		},
		async onGetMemo() {
			let params = {
				offset: this.memo.offset,
				limit: this.memo.limit,
				buildingId: this.buildingId,
				floorId: this.floorId,
				roomId: this.roomId,
			};
			try {
				let res = await memo.get(params);
				this.memo.total = res.total;
				if (this.memo.total > 0) {
					res.data.forEach(p => this.memoList.push(p));
					this.memo.offset += this.memo.limit;
				}
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
		},

		onMemo(id) {
			this.$router.push({ name: 'memoDetail', params: { id: id, reqUrl: 'room' } });
		},
		onMemoList(roomId, objectPinId) {
			this.$router.push({
				name: 'memoList',
				params: { buildingId: this.buildingId, floorId: this.floorId, roomId: roomId, objectPinId: objectPinId },
			});
		},
		onMemoRegist(roomId, objectPinId) {
			this.$router.push({
				name: 'memoRegist',
				params: {
					buildingId: this.buildingId,
					floorId: this.floorId,
					roomId: roomId,
					objectPinId: objectPinId,
					reqUrl: 'room',
				},
			});
		},
		async ablePin(objectPin, isUse) {
			if (isUse == false)
				if (
					!(await this.$confirm(
						'선택하신 오브제 영역을 비활성화하시겠습니까? <br/>도면 내에 오브제 영역이 노출되지 않습니다',
						'오브제 안내',
					))
				) {
					this.objectPins.forEach(op => (op.style = null));
					return false;
				}

			let params = {
				id: objectPin.id,
				roomId: 0,
				objectId: 0,
				isUse: isUse,
			};
			try {
				showSpinner();
				await objectpin.update(params);
			} catch (ex) {
				return await this.$alert(ex);
			} finally {
				hideSpinner();
			}
			this.reload();
		},
		viewMenu(index) {
			if (this.objectPins[index].style == null) {
				this.objectPins.forEach(op => (op.style = null));
				this.objectPins[index].style = 'display: block;';
			} else {
				this.objectPins.forEach(op => (op.style = null));
			}
		},
		async friendInfo() {
			let res = await building.getOne(this.buildingId);
			if (getAuth().id != res.owner) {
				let params = {
					buildingId: this.buildingId,
					userId: res.owner,
					friendId: getAuth().id,
					state: 'F',
				};
				let res2 = await friend.getOne(params);
				if (res2.authority == 'A') this.friendAuth = true;
			} else {
				this.friendAuth = true;
			}
		},
		onBuilding(buildingId) {
			this.$router.push({ name: 'buildingDetail', params: { id: buildingId } });
		},
		onFloor(floorId) {
			this.$router.push({ name: 'floorDetail', params: { id: floorId } });
		},
		onRoom(roomId) {
			this.$router.push({ name: 'roomDetail', params: { id: roomId } });
		},
		enterContent(content) {
			let count = content.match(/\n/g);
			if (count != null && count.length > 4) {
				let tmp = '';
				for (let i = 0; i < 5; i++) {
					tmp += content.split('\n')[i] + '\n';
				}
				content = tmp.substring(0, tmp.length - 1);
			}
			return content;
		},
		onOpenImage(imageId, memoFiles) {
			this.$refs.popUpImage.onOpenImage(imageId, memoFiles);
		},
		async onGetObjectCode() {
			try {
				showSpinner();
				let codeList = await code.getDetail(this.groupCode);
				this.menus = codeList.data;
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		async moveObjectPin(objectPin) {
			const res = await this.$refs.popupMoveRoom.select(objectPin);
			if (!res) return;
			if (objectPin.roomId === res) return;

			try {
				showSpinner();
				await objectpin.update({ id: objectPin.id, roomId: res });
			} catch (ex) {
				return await this.$alert(`오브제핀 실이동 실패했습니다.\r\n${ex.message}`);
			} finally {
				hideSpinner();
			}

			await this.reload();
		},
		async deleteObjectPin(objectPin) {
			if (!(await this.$confirm('선택하신 오브제 영역을 삭제하시겠습니까?', '오브제 안내'))) {
				this.objectPins.forEach(op => (op.style = null));
				return;
			}
			try {
				showSpinner();
				await objectpin.remove(objectPin.id);
				await this.reload();
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		onFocusObjectPin(pin) {
			this.selectedPin = pin;
		},
		onBlurObjectPin() {
			this.selectedPin = null;
		},
		onTouchstart(ev) {
			ev.preventDefault();

			const { target, touches } = ev;
			const { clientX, clientY } = touches[0];

			target.dispatchEvent(new MouseEvent('mousedown', { clientX, clientY }));
		},
		onMousedown() {
			this.selectedPin = null;
			// ev.preventDefault();
		},
		setIntervalStr(intervalType) {
			return setIntervalStr(intervalType);
		},
		setDateFormat(schedule) {
			let date = schedule.beginDate;
			switch (schedule.intervalType) {
				case 'Y':
					date = this.$moment(date).format('M월 D일');
					break;
				case 'M':
					date = this.$moment(date).format('D일');
					break;
				case 'W':
					switch (this.$moment(date).day()) {
						case 0:
							date = '일요일';
							break;
						case 1:
							date = '월요일';
							break;
						case 2:
							date = '화요일';
							break;
						case 3:
							date = '수요일';
							break;
						case 4:
							date = '목요일';
							break;
						case 5:
							date = '금요일';
							break;
						case 6:
							date = '토요일';
							break;
					}
					break;
				case 'D':
					date = this.$moment(date).format('YYYY월 M월 D일');
					break;
			}
			return date;
		},
	},
};
</script>
<style scoped>
.objectList02 li:focus {
	outline: 2px solid #0c234c;
}
.locationCount {
	padding-left: 5px;
	background: none;
}
.floorPlanArea {
	position: relative;
	overflow: hidden;
	height: 400px;
}
@media (max-width: 767px) {
	.floorPlanArea {
		height: 300px;
	}
}
</style>
